*
{
    margin: 0;
    padding: 0;
    background: transparent;
}

html,
body
{
    width:100%;
    height:100%;
    overflow: hidden;
    background-color: beige;
    touch-action: none;
}

.canvas
{
    position: fixed;
    outline: none;
    background-color: rgb(0, 0, 0);
}

.button {
    position: absolute;
    visibility: hidden;
    display: none;
}

.slice {
    position: absolute;
    visibility: hidden;
    display: none;
}

.bkg {
    visibility: hidden;
}

.text {
	position: fixed;
    margin: 0;
    padding: 0;
    font-family: arial;
    text-align: right;
    background: transparent;
}

#div {
    background-color: black;
}

.help1 {
    position: absolute;
    visibility: hidden;
    font-family: arial;
    font-size: 20px;
    color:rgb(217, 23, 23);
    background-color: rgb(0, 0, 0);
}

.caseSelection {
    position: absolute;
    font-family: arial;
    font-size: 24px;
    color: white;
    background-color:rgb(217, 23, 23);
    padding: 4px;
    border-radius: 10px;
    border: 2px solid;
    display: block;
    cursor: pointer;
}

.title {
    position: absolute;
    font-family: arial;
    font-size: 24px;
    font-style: oblique;
	text-align: center;
    color:white;
    background-color: rgb(0, 0, 0);
	display: inline-block;
}